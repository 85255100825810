import { render } from "./DashboardRiskCountBadge.vue?vue&type=template&id=96283988"
import script from "./DashboardRiskCountBadge.vue?vue&type=script&lang=js"
export * from "./DashboardRiskCountBadge.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "96283988"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('96283988', script)) {
    api.reload('96283988', script)
  }
  
  module.hot.accept("./DashboardRiskCountBadge.vue?vue&type=template&id=96283988", () => {
    api.rerender('96283988', render)
  })

}

script.__file = "src/components/dashboard/DashboardRiskCountBadge.vue"

export default script